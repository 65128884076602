import React from 'react';
import {
    Modal,
    Flex,
    Text,
    Input,
    Button,
    Box,
    FormControl,
} from '@landr/maestro';
import { useForm } from 'react-hook-form';
import { useAuthStepUpModal } from '../../AuthStepUpModalContext';

const CodeKey = 'code';

export const VerificationCode: React.FC = () => {
    const { twoFactorId, handleSubmitCodeAndRetryAction } =
        useAuthStepUpModal();

    const { register, formState, handleSubmit } = useForm<{ code: string }>({
        mode: 'onSubmit',
    });

    const handleOnSubmit = (values: { code: string }) => {
        if (twoFactorId) {
            handleSubmitCodeAndRetryAction({
                twoFactorId,
                code: values.code,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Modal.Section p={{ base: 'jb', mdDown: 'xl' }} pt="0" pb="sm">
                <Flex flexDirection="column" pb="xxl">
                    <Flex flexDirection="column" pb="xl">
                        <Text
                            fontSize={{ base: 'xxxl', mdDown: 'xxl' }}
                            fontWeight="bold"
                            letterSpacing="-1px"
                        >
                            Enter verification code
                        </Text>
                    </Flex>
                    <Flex flexDirection="column">
                        <Box pb="xl">
                            <FormControl
                                marginBottom="sm"
                                isInvalid={!!formState.errors.code}
                            >
                                <Input
                                    placeholder="Enter your verification or recovery code"
                                    {...register(CodeKey, { required: true })}
                                />
                                <FormControl.ValidationMessage>
                                    Required
                                </FormControl.ValidationMessage>
                            </FormControl>
                        </Box>
                        <Button isRounded type="submit">
                            Continue
                        </Button>
                    </Flex>
                </Flex>
            </Modal.Section>
        </form>
    );
};
