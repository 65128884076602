import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { log } from 'utils/log';
import { neverop, noop } from 'helpers/noop';
import { VersionChange } from 'enums/versionChange';
import { EventIds } from 'enums/eventIds';

export type ClientUpdateContextType = {
    updateRequired: VersionChange | false;
    setUpdateRequired: (updateRequired: VersionChange | false) => void;
    performClientReload: () => void;
};

const emptyContext = {
    setUpdateRequired: noop,
    updateRequired: false as VersionChange | false,
    performClientReload: neverop('ClientUpdateContext', 'performClientReload'),
};

export const ClientUpdateContext =
    React.createContext<ClientUpdateContextType>(emptyContext);

export const useClientUpdateContext = (): ClientUpdateContextType =>
    useContext(ClientUpdateContext);

export const ClientUpdateProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const [updateRequired, setUpdateRequired] = useState<VersionChange | false>(
        false,
    );
    const { pathname } = useLocation();

    const performClientReload = useCallback(() => {
        log.warn(
            `Client Update Context Reload Performed`,
            EventIds.ClientUpdateContextPerformReload,
        );
        window.location.reload();
    }, []);

    useEffect(() => {
        // Refresh the page on location change for Major/Minor version changes
        if (updateRequired) {
            performClientReload();
        }
        // Only run on location change, not when update required is set
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <ClientUpdateContext.Provider
            value={{ updateRequired, setUpdateRequired, performClientReload }}
        >
            {children}
        </ClientUpdateContext.Provider>
    );
};

export default ClientUpdateProvider;
