export const getLoggableVariables = (variables?: Record<string, any>) => {
    if (!variables) {
        return undefined;
    }

    const stringifyVariables = JSON.stringify(variables);

    const canLogVariables =
        stringifyVariables.toLocaleLowerCase().indexOf('password') === -1;

    const loggableVariables = canLogVariables
        ? variables
        : 'Unsafe to log variables';

    return loggableVariables;
};
