import { gql } from '@apollo/client';

export const TwoFactorStartMutation = gql`
    mutation twoFactorStart {
        twoFactorStart {
            twoFactorId
            methods {
                id
                type
            }
        }
    }
`;

export const TwoFactorSendMutation = gql`
    mutation twoFactorSend($input: TwoFactorSendInput!) {
        twoFactorSend(input: $input)
    }
`;
