import React, { useContext, useState } from 'react';
import { TwoFactorMethod, TwoFactorInput } from 'types/generated/graphql';
import { AuthStepUpModal } from './AuthStepUpModal';
import { AuthStepUpModalStep } from './types';

export interface AuthStepUpModalContextProps {
    openModal: (retryAction: (twoFactor: TwoFactorInput) => void) => void;
    isOpen: boolean;
    onClose(): void;
    step: AuthStepUpModalStep;
    methods?: TwoFactorMethod[];
    setMethods: (methods: TwoFactorMethod[]) => void;
    twoFactorId?: string;
    setTwoFactorId: (twoFactorId: string) => void;
    handleNavigateToNextStep: () => void;
    handleNavigateToPreviousStep: () => void;
    handleSubmitCodeAndRetryAction: (twoFactor: TwoFactorInput) => void;
}

const AuthStepUpModalContext = React.createContext<AuthStepUpModalContextProps>(
    {
        step: AuthStepUpModalStep.MethodSelection,
        openModal: () => undefined,
        isOpen: false,
        onClose: () => undefined,
        setMethods: () => undefined,
        setTwoFactorId: () => undefined,
        handleNavigateToNextStep: () => undefined,
        handleNavigateToPreviousStep: () => undefined,
        handleSubmitCodeAndRetryAction: () => undefined,
    },
);

export const AuthStepUpModalProvider: React.FC = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(
        AuthStepUpModalStep.MethodSelection,
    );
    const [methods, setMethods] = useState<TwoFactorMethod[]>();
    const [twoFactorId, setTwoFactorId] = useState<string>();
    const [twoFactorRequiredAction, setTwoFactorRequiredAction] =
        useState<(twoFactor: TwoFactorInput) => void>();

    const openModal = (action: (twoFactor: TwoFactorInput) => void) => {
        setTwoFactorId('');
        setTwoFactorRequiredAction(() => action);
        setIsVisible(true);
    };

    const closeModal = () => {
        setCurrentStep(AuthStepUpModalStep.MethodSelection);
        setIsVisible(false);
    };

    const handleNavigateToNextStep = () => {
        setCurrentStep(AuthStepUpModalStep.VerificationCode);
    };

    const handleNavigateToPreviousStep = () =>
        setCurrentStep(AuthStepUpModalStep.MethodSelection);

    const handleSubmitCodeAndRetryAction = (twoFactor: TwoFactorInput) => {
        twoFactorRequiredAction?.(twoFactor);
        closeModal();
    };

    return (
        <AuthStepUpModalContext.Provider
            value={{
                openModal,
                methods,
                setMethods,
                twoFactorId,
                setTwoFactorId,
                step: currentStep,
                handleNavigateToNextStep,
                handleNavigateToPreviousStep,
                isOpen: isVisible,
                onClose: closeModal,
                handleSubmitCodeAndRetryAction,
            }}
        >
            {children}
            <AuthStepUpModal />
        </AuthStepUpModalContext.Provider>
    );
};

export const useAuthStepUpModal = () => useContext(AuthStepUpModalContext);
