import { RetryLink } from '@apollo/client/link/retry';
import { log } from 'utils/log';
import { EventIds } from 'enums/eventIds';
import { getLoggableVariables } from 'helpers/getLoggableVariables/getLoggableVariables';

const InitialDelayInMs = 500;
const MaxAttempts = 3;
// Exponential backoff doubles the delay for each attempt.
// We add the initial delay to prevent last attempt rejection.
const MaxDelay = Math.pow(2, MaxAttempts) * InitialDelayInMs + InitialDelayInMs;

// Which graphql operation we don't want to retry
const notRetryableOperations = new Set<string>([]);

const delay: RetryLink.Options['delay'] = {
    initial: InitialDelayInMs,
    max: MaxDelay,
    jitter: true,
};

const attempts: RetryLink.Options['attempts'] = (count, operation, error) => {
    const canRetry = !notRetryableOperations.has(operation.operationName);

    if (count > MaxAttempts || !canRetry) {
        return false;
    }

    const hasError = !!error;
    const { headers } = operation.getContext();
    const operationVariables = getLoggableVariables(operation?.variables);

    if (hasError) {
        log.info(
            `[Operation]: ${operation?.operationName}, [Retry count]: ${count}, [Error]: ${error}`,
            EventIds.ApolloNetworkError,
            {
                error: error,
                operationName: operation?.operationName,
                variables: operationVariables,
                correlationId: headers['X-CorrelationId'],
            },
        );
    } else if (count > 1) {
        log.info(
            `[Operation]: ${operation?.operationName} succeeded after ${count} retries`,
            EventIds.ApolloNetworkError,
            {
                operationName: operation?.operationName,
                variables: operationVariables,
                correlationId: headers['X-CorrelationId'],
            },
        );
    }

    return hasError && canRetry;
};

export const retryLink = new RetryLink({ delay, attempts });
