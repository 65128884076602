import React from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import { useVisible } from '@landr/maestro';
import { useLocation } from 'react-router-dom';

export interface ModalContextValue<T> {
    isModalVisible: boolean;
    openModal: (modalData?: T) => void;
    closeModal: () => void;
    closeModalAndReset: () => void;
    modalData?: T;
    setModalData: (modalData: T) => void;
}

export interface ModalContext<T> {
    useModal: (options?: CreateModalContextOptions) => ModalContextValue<T>;
    modalProvider: React.ComponentType;
    modalContext: React.Context<ModalContextValue<T>>;
}

export interface CreateModalContextOptions {
    closeModalOnLocationChange: boolean;
}

const defaultOptions = {
    closeModalOnLocationChange: true,
};

export function createModalContext<T>(
    options: CreateModalContextOptions = defaultOptions,
): ModalContext<T> {
    const ModalContext = createContext<ModalContextValue<T>>({
        isModalVisible: false,
        closeModal: () => undefined,
        closeModalAndReset: () => undefined,
        openModal: () => undefined,
        setModalData: () => undefined,
        modalData: undefined,
    });

    const useModal = (optionsOverwrite?: CreateModalContextOptions) => {
        if (optionsOverwrite) {
            options = { ...options, ...optionsOverwrite };
        }
        return useContext(ModalContext);
    };

    const ModalProvider: React.FC = ({ children }) => {
        const { visible, show, hide } = useVisible();
        const [modalData, setModalData] = useState<T>();
        const { pathname, search } = useLocation();

        const openModal = (modalData?: T) => {
            if (modalData) {
                setModalData(modalData);
            }
            show();
        };

        const closeModal = () => {
            hide();
        };

        const closeModalAndReset = () => {
            setModalData(undefined);
            hide();
        };

        useEffect(() => {
            if (visible && options.closeModalOnLocationChange) {
                closeModal();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [pathname, search, options.closeModalOnLocationChange]);

        return (
            <ModalContext.Provider
                value={{
                    isModalVisible: visible,
                    closeModal,
                    closeModalAndReset,
                    openModal,
                    setModalData,
                    modalData,
                }}
            >
                {children}
            </ModalContext.Provider>
        );
    };

    return {
        modalProvider: ModalProvider,
        modalContext: ModalContext,
        useModal,
    };
}
