import React, { useEffect } from 'react';
import { Modal, useNotification } from '@landr/maestro';
import { useMutation } from '@apollo/client';
import {
    TwoFactorStartMutation as TwoFactorStartMutationType,
    TwoFactorStartMutationVariables,
} from 'types/generated/graphql';
import { EventIds } from 'enums/eventIds';
import { log } from '../../utils/log';
import { AuthStepUpModalStep } from './types';
import { MethodSelection, VerificationCode } from './components';
import { useAuthStepUpModal } from './AuthStepUpModalContext';
import { TwoFactorStartMutation } from './mutations';

const AuthStepUpModalContent: Record<AuthStepUpModalStep, React.FC> = {
    [AuthStepUpModalStep.MethodSelection]: MethodSelection,
    [AuthStepUpModalStep.VerificationCode]: VerificationCode,
};

export const AuthStepUpModal: React.FC = () => {
    const {
        isOpen,
        onClose,
        step,
        handleNavigateToPreviousStep,
        setMethods,
        setTwoFactorId,
    } = useAuthStepUpModal();
    const { notify } = useNotification();
    const [twoFactorStart, { loading }] = useMutation<
        TwoFactorStartMutationType,
        TwoFactorStartMutationVariables
    >(TwoFactorStartMutation, {
        onError(error) {
            log.error(
                'Failed to start two-factor step up',
                EventIds.TwoFactorStartError,
                error,
            );
            notify({
                variant: 'danger',
                content: 'An error occurred. Please try again later.',
            });
            onClose();
        },
    });

    useEffect(() => {
        const initializeAuthStepUp = async () => {
            if (isOpen) {
                const { data } = await twoFactorStart();

                if (
                    data?.twoFactorStart.methods &&
                    data.twoFactorStart.methods.length &&
                    data.twoFactorStart.twoFactorId
                ) {
                    setMethods(data.twoFactorStart.methods);
                    setTwoFactorId(data.twoFactorStart.twoFactorId);
                } else {
                    // This should never happen, if backend tells us to start 2FA, the user should have at least one method enabled
                    log.error(
                        'No two factor methods or twoFactorId returned',
                        EventIds.TwoFactorStartMissingMethodOrTwoFactorIdError,
                    );
                    notify({
                        variant: 'danger',
                        content: 'An error occurred. Please try again later.',
                    });
                    onClose();
                }
            }
        };

        initializeAuthStepUp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    if (loading) {
        return null;
    }

    const Content = AuthStepUpModalContent[step];

    return (
        <Modal isOpen={isOpen} onClose={onClose} css={{ maxW: '480px' }}>
            <Modal.Header>
                {step === AuthStepUpModalStep.VerificationCode && (
                    <Modal.BackButton
                        label="Back"
                        onClick={handleNavigateToPreviousStep}
                    />
                )}
            </Modal.Header>
            <Modal.Content>
                <Content />
            </Modal.Content>
        </Modal>
    );
};
