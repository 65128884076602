import React from 'react';
import {
    Modal,
    Flex,
    Text,
    Button,
    Divider,
    MailOutlined,
    MobilePhoneOutlined,
    useNotification,
} from '@landr/maestro';
import { useMutation } from '@apollo/client';
import { EventIds } from 'enums/eventIds';
import { log } from 'utils/log';
import { useAuthStepUpModal } from '../../AuthStepUpModalContext';
import {
    TwoFactorMethod,
    TwoFactorMethodType,
    TwoFactorSendMutation as TwoFactorSendMutationType,
    TwoFactorSendMutationVariables,
} from '../../../../types/generated/graphql';
import { TwoFactorSendMutation } from '../../mutations';

const getMethodIcon = (type: TwoFactorMethodType) => {
    const methodIcons = {
        [TwoFactorMethodType.Email]: <MailOutlined />,
        [TwoFactorMethodType.Authenticator]: <MobilePhoneOutlined />,
        [TwoFactorMethodType.Unknown]: undefined,
    };

    return methodIcons[type];
};

const getMethodName = (type: TwoFactorMethodType) => {
    const methodNames = {
        [TwoFactorMethodType.Email]: 'Email',
        [TwoFactorMethodType.Authenticator]: 'Authenticator app',
        [TwoFactorMethodType.Unknown]: undefined,
    };

    return methodNames[type];
};

export const MethodSelection: React.FC = () => {
    const { methods, handleNavigateToNextStep, twoFactorId } =
        useAuthStepUpModal();
    const { notify } = useNotification();

    const [twoFactorSend, { loading: isTwoFactorSendLoading }] = useMutation<
        TwoFactorSendMutationType,
        TwoFactorSendMutationVariables
    >(TwoFactorSendMutation, {
        onError(error) {
            log.error(
                'Failed to send two-factor code',
                EventIds.TwoFactorSendError,
                error,
            );
            notify({
                variant: 'danger',
                content:
                    'Failed to send verification code. Please try again later.',
            });
        },
        onCompleted() {
            handleNavigateToNextStep();
        },
    });

    const handleSelectMethod = (method: TwoFactorMethod) => {
        // Call twoFactorSend only for Email, as the Authenticator app does not require this extra step.
        if (method.type === TwoFactorMethodType.Email && twoFactorId) {
            twoFactorSend({
                variables: {
                    input: {
                        twoFactorId: twoFactorId,
                        methodId: method.id,
                    },
                },
            });
        } else {
            handleNavigateToNextStep();
        }
    };

    return (
        <Modal.Section p={{ base: 'jb', mdDown: 'xl' }} pt="0" pb="sm">
            <Flex flexDirection="column" pb="xxl">
                <Flex flexDirection="column" pb="lg">
                    <Text
                        fontSize={{ base: 'xxxl', mdDown: 'xxl' }}
                        fontWeight="bold"
                    >
                        Two-step verification
                    </Text>
                    <Text color="text.secondary">
                        An authentication step is required to complete this
                        action. Select one of the following methods to continue.
                    </Text>
                </Flex>
                {methods?.map((method, index) => {
                    if (method.type === TwoFactorMethodType.Unknown) {
                        return null;
                    }

                    const methodName = getMethodName(method.type);

                    return (
                        <Flex flexDirection="column" key={method.id}>
                            {index === 0 && <Divider />}
                            <Flex
                                flexDirection="row"
                                alignItems="center"
                                paddingY="xs"
                            >
                                <Flex mr="auto">
                                    {getMethodIcon(method.type)}
                                    <Text fontWeight="bold" pl="8px">
                                        {methodName}
                                    </Text>
                                </Flex>
                                <Flex>
                                    <Button
                                        onClick={() =>
                                            handleSelectMethod(method)
                                        }
                                        variant="ghost"
                                        isRounded
                                        isLoading={
                                            isTwoFactorSendLoading &&
                                            method.type ===
                                                TwoFactorMethodType.Email
                                        }
                                        isDisabled={
                                            isTwoFactorSendLoading &&
                                            method.type !==
                                                TwoFactorMethodType.Email
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Flex>
                            </Flex>
                            {index < methods.length && <Divider />}
                        </Flex>
                    );
                })}
            </Flex>
        </Modal.Section>
    );
};
