import { UTMCookieSettings } from 'constants/UTMCookieSettings';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useIsDestroyed } from '@landr/maestro';
import { saveOfflineConversionToCookies } from '@landr/core';
import { EventIds } from 'enums/eventIds';
import { auth } from 'utils/auth';
import { CONFIG } from 'utils/config';
import { analytics, sendAnalyticsIdentify } from 'utils/analytics';
import { log } from 'utils/log';
import { featureFlagService } from 'utils/featureFlagService';
import { scriptLoader } from 'utils/scriptLoader';
import PageLoader from 'components/PageLoader';
import { useNavigate } from 'react-router-dom';

const environment = CONFIG.VITE_APP_ENV;

function getOfflineUrl(): string {
    return `${CONFIG.VITE_OFFLINE_URL}?returnUrl=${encodeURIComponent(
        window.location.href,
    )}`;
}

function loadAnalytics() {
    if (!window.prerenderCloudIsServerSideRendering) {
        analytics.load(CONFIG.VITE_SEGMENT_WRITE_KEY);
    }
}

// Exported for unit test purpose only
export async function loadRewardsScript(): Promise<void> {
    const apiKey = CONFIG.VITE_FIRST_PROMOTER_API_KEY;

    function injectFirstPromoterConfigScript() {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.async = true;

        script.innerHTML = `(function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
fpr("init", {cid:"${apiKey}"});
fpr("click");`;

        const firstScriptOnPage = document.getElementsByTagName('script')[0];

        firstScriptOnPage?.parentNode?.insertBefore(script, firstScriptOnPage);
    }

    injectFirstPromoterConfigScript();

    return scriptLoader.loadScript(
        'https://d16mfdj9kvafcs.cloudfront.net/fpr.js',
    );
}

// Exporting for test purposes
export const Bootstrap: React.FC<PropsWithChildren<unknown>> = ({
    children,
}) => {
    const [isReady, setIsReady] = useState(false);
    const isDestroyed = useIsDestroyed();
    const navigate = useNavigate();

    useEffect(() => {
        async function bootstrap() {
            try {
                if (isDestroyed.current) return;

                // Save offline conversion to cookies before authenticating
                saveOfflineConversionToCookies(UTMCookieSettings);

                auth.initialize({ environment, offlineUrl: getOfflineUrl() });

                const user = await auth.getUser();

                if (user) {
                    await Promise.all([
                        featureFlagService.load(true),
                        sendAnalyticsIdentify(),
                    ]);

                    loadRewardsScript();
                    loadAnalytics();
                    const appState = auth.getAppState();

                    if (appState?.targetUrl) {
                        navigate(appState.targetUrl);
                    }
                }
            } catch (error) {
                log.error(
                    'Failed to bootstrap synchro-arts',
                    EventIds.BootstrapError,
                    error,
                );
            } finally {
                if (!isDestroyed.current) {
                    setIsReady(true);
                }
            }
        }

        bootstrap();
    }, [isDestroyed]);

    return <>{isReady ? children : <PageLoader />}</>;
};
