import React from 'react';
import { styled } from '@landr/styled-system/jsx';
import { Box, InfoOutlined, Tooltip } from '@landr/maestro';

const ErrorWrapper = styled(Box, {
    base: {
        position: 'relative',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '3px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'neutral.800',
    },
});

const Icon = styled(InfoOutlined, {
    base: {
        color: 'neutral.800',
    },
});

const IconWrapper = styled(Box, {
    base: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
});

// Use `withErrorBoundary` with the variant `minimal` or use it directly in your component
// when you are handling an error and wish to render only a minimal ui.
// For more info consult error/README.md
const MinimalError = () => {
    return (
        <ErrorWrapper>
            <Tooltip content="Failed to load" position="top" hasArrow>
                <IconWrapper>
                    <Icon size="md" />
                </IconWrapper>
            </Tooltip>
        </ErrorWrapper>
    );
};

export default MinimalError;
