import { EventIds } from 'enums/eventIds';
import React from 'react';
import { log } from 'utils/log';
import { getIsOnline } from 'helpers/getIsOnline';
import { delay } from 'helpers/delay';

const MaxAttempts = 5;
const BaseDelayMs = 250;

// Based on https://github.com/fatso83/retry-dynamic-import/tree/main
const exponentialBackoffRetry = async <T>(
    importer: () => Promise<{ default: React.ComponentType<T> }>,
): Promise<any> => {
    for (let attempt = 1; attempt <= MaxAttempts; attempt++) {
        const isLastTentative = attempt === MaxAttempts;

        if (isLastTentative || getIsOnline()) {
            return importer();
        } else {
            await delay(BaseDelayMs * 2 ** attempt);

            log.warn(
                `Browser offline - Retry lazy import`,
                EventIds.RetryLazyImport,
                undefined,
                {
                    attempt: attempt,
                },
            );
        }
    }

    throw new Error('LazyWithRetries fail to load module');
};

export const lazyWithRetries = <T>(
    importer: () => Promise<{ default: React.ComponentType<T> }>,
) => {
    return React.lazy(() => exponentialBackoffRetry(importer));
};
