import React from 'react';
import { Center, Progress } from '@landr/maestro';

const PageLoader = () => (
    <Center role="status" h="screen">
        <Progress size="lg" />
    </Center>
);

export default PageLoader;
