import { EventIds } from 'enums/eventIds';
import { log } from 'utils/log';
import { auth } from 'utils/auth/auth';
import { analytics } from '.';

export async function sendAnalyticsIdentify() {
    let analyticsProperties = null;

    const user = await auth.getUser();

    if (user) {
        try {
            analyticsProperties = await auth.getAnalyticsProperties();
        } catch (e) {
            log.error(
                'Error getting analytics properties',
                EventIds.FailedGetAnalyticsPropertiesError,
                e,
            );
        }

        // identify with feature flags
        await analytics.identify(user.userId, analyticsProperties);
    }
}
