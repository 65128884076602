import React, { useState, useEffect, useContext } from 'react';
import { useIsDestroyed } from '@landr/maestro';
import { User } from '@landr/auth';
import { auth } from 'utils/auth/auth';

export interface AuthStateContextProps {
    user: User | null;
    isAuthenticated: boolean;
    isLoading: boolean;
}

export const AuthStateContext =
    React.createContext<AuthStateContextProps | null>(null);

export interface AuthStateProviderProps {
    children: React.ReactNode;
}

export const AuthStateProvider: React.FC<AuthStateProviderProps> = ({
    children,
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [user, setUser] = useState<User | null>(null);
    const isDestroyed = useIsDestroyed();

    useEffect(() => {
        Promise.all([auth.getUser()])
            .then(([user]) => {
                if (isDestroyed.current) {
                    return;
                }
                setIsAuthenticated(Boolean(user));
                if (user) {
                    setUser(user);

                    // clear localstorage products list when user changes - used in useDynamicProductCollapse
                    if (
                        user &&
                        user.userId !== localStorage.getItem('userID')
                    ) {
                        localStorage.setItem('userID', user.userId);
                        localStorage.removeItem('products');
                        localStorage.removeItem('canShowRedirectionModal');
                    }
                }
            })
            .catch(() => {
                if (isDestroyed.current) {
                    return;
                }
                setIsAuthenticated(false);
            })
            .finally(() => {
                if (isDestroyed.current) {
                    return;
                }
                setIsLoading(false);
            });
    }, [isDestroyed]);

    return (
        <AuthStateContext.Provider
            value={{
                user,
                isAuthenticated,
                isLoading,
            }}
        >
            {children}
        </AuthStateContext.Provider>
    );
};

export const useAuthStateContext = (): AuthStateContextProps => {
    const context = useContext<AuthStateContextProps | null>(AuthStateContext);

    if (context === null) {
        throw new Error(
            'useAuthStateContext must be used within a AuthStateProvider',
        );
    }
    return context;
};
