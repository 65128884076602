import { APP_VERSION } from 'constants/app';
import { createHttpLink, ApolloLink } from '@apollo/client';
import { getXLandrAnalyticsV2, AnalyticsApplication } from '@landr/core';
import { featureFlagService } from 'utils/featureFlagService';
import { CONFIG } from 'utils/config';
import { auth } from 'utils/auth/auth';

export const customFetch = async (
    input: RequestInfo | URL,
    init?: RequestInit | undefined,
): Promise<Response> => {
    const token = await auth.getAccessToken();

    const authorization = token
        ? { authorization: `Bearer ${token}` }
        : undefined;

    const modifiedOptions = {
        ...init,
        headers: {
            ...init?.headers,
            'X-LANDR-ANALYTICS': JSON.stringify(
                getXLandrAnalyticsV2(AnalyticsApplication.SynchroArts),
            ),
            'X-LANDR-FEATUREFLAGS': JSON.stringify(featureFlagService.get()),
            ...authorization,
        },
    };

    return fetch(input, modifiedOptions);
};

export const httpLink = createHttpLink({
    fetch: customFetch,
    uri: CONFIG.VITE_GRAPHQL_API,
    headers: {
        'X-LANDR-CLIENT': `version=${APP_VERSION}, kind=${AnalyticsApplication.SynchroArts}`,
        'X-API-Languages': 'en-US',
    },
});

export const transportLink: ApolloLink = httpLink;
