import React from 'react';
import { styled } from '@landr/styled-system/jsx';
import {
    Box,
    Heading,
    Paragraph,
    Button,
    Container,
    ContainerProps,
    Stack,
    Center,
} from '@landr/maestro';
import { Helmet } from 'react-helmet';
import AppStyleProvider from 'components/AppStyleProvider';
import { SynchroArtsLogo } from 'components/SynchroArtsLogo';

const StyledLink = styled('a', {
    base: {
        textDecoration: 'underline',
    },
});

const refreshPage = () => {
    window?.location?.reload();
};

// `PageError` wrap VerboseError component in a page layout.
// This is what is returned by `withErrorBoundary(Component, 'page');`
// It should only be use for pages or components that include page layout structure.
// If your component do not have page layout structure, use `<VerboseError />` directly
// For more info consult error/README.md
const PageError = ({
    isGlobal = false,
    status,
}: {
    isGlobal?: boolean;
    status?: number;
}) => {
    let size: ContainerProps['size'];
    let title: JSX.Element;
    let description: JSX.Element;
    let action: JSX.Element;

    switch (status) {
        case 404:
            size = 'md';
            title = <>This page does not exist</>;
            description = (
                <>
                    If the problem persists{' '}
                    <StyledLink
                        href="https://synchroarts.zendesk.com"
                        target="_blank"
                    >
                        contact support
                    </StyledLink>{' '}
                    for more help.
                </>
            );
            action = (
                <Button as="a" href="/" variant="secondary" isRounded>
                    Back to Dashboard
                </Button>
            );
            break;

        default:
            size = 'ms';
            title = <>Something wasn't plugged right.</>;
            description = (
                <>
                    We couldn't find what you were looking for. If the problem
                    persists, check that your browser is up-to-date or{' '}
                    <StyledLink
                        href="https://synchroarts.zendesk.com"
                        target="_blank"
                    >
                        contact support
                    </StyledLink>{' '}
                    for more help.
                </>
            );
            action = (
                <Button variant="secondary" isRounded onClick={refreshPage}>
                    Refresh
                </Button>
            );
    }

    return (
        <>
            {isGlobal && (
                <Helmet>
                    <meta name="prerender-status-code" content="500" />
                </Helmet>
            )}
            <AppStyleProvider>
                <Center>
                    <Container
                        size={size}
                        display="flex"
                        alignItems="center"
                        textAlign="center"
                        justifyContent="center"
                        minHeight={isGlobal ? '100vh' : 'auto'}
                    >
                        <Stack
                            display="flex"
                            alignItems="center"
                            textAlign="center"
                            justifyContent="center"
                            py="jb"
                        >
                            {isGlobal && (
                                <Box mb="xl">
                                    <SynchroArtsLogo />
                                </Box>
                            )}
                            <Heading
                                textStyle={{
                                    base: 'headline',
                                    mdDown: 'headline-sm',
                                }}
                                data-testid="big-title"
                                mb="md"
                                as="h1"
                            >
                                {title}
                            </Heading>
                            <Paragraph color="text.subtle" mb="2xl">
                                {description}
                            </Paragraph>
                            {action}
                        </Stack>
                    </Container>
                </Center>
            </AppStyleProvider>
        </>
    );
};

export default PageError;
