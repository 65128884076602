// Basic feature flag service
// This service can only work with anonymous users as the authentication token won't be passed, we don't want to wait for it
// Based on LANDR.Web.Projects/apps/root-app/src/sharedLibs/@landr/root-feature-flags.ts
import {
    areDevToolsActive,
    FlagUpdatePayload,
    getCachedForcedFlags,
    sendToDevTools,
    subscribeFromDevTools,
} from '@landr/core';
import { ApplicationEnum } from '@landr/core.models';
import { CONFIG } from 'utils/config';
import { log } from 'utils/log';
import { Observer } from 'helpers/eventObserver';
import { EventIds } from 'enums/eventIds';
import { FeatureFlags } from 'enums/featureFlags';
import { auth } from 'utils/auth/auth';

export type ActiveFlags = {
    [k in FeatureFlags]: boolean;
};

export const defaultFeatureFlags: ActiveFlags = {
    [FeatureFlags.SaSubscriptions]: false,
    [FeatureFlags.RePitchILokPhaseOut]: false,
    [FeatureFlags.TwoFactorAuthentication]: false,
};

export class FeatureFlagService {
    private featureFlags: ActiveFlags = defaultFeatureFlags;
    private loadPromise: Promise<boolean> | null = null;

    public observers = new Set<Observer<Partial<ActiveFlags>>>();

    public subscribe(observer: Observer<Partial<ActiveFlags>>): void {
        this.observers.add(observer);
    }

    public unsubscribe(observer: Observer<Partial<ActiveFlags>>): void {
        this.observers.delete(observer);
    }

    private broadcast(data: Partial<ActiveFlags>): void {
        this.observers.forEach((observer) => {
            observer.callback(data);
        });
    }

    load(isAuthenticated: boolean): Promise<boolean> {
        if (!this.loadPromise) {
            this.loadPromise = isAuthenticated
                ? auth.getAccessToken().then((token) => this.loadFlags(token))
                : this.loadFlags(null);
        }

        return this.loadPromise;
    }

    get(): ActiveFlags {
        return this.featureFlags;
    }

    private async loadFlags(token: string | null): Promise<boolean> {
        const cachedForcedFlags = getCachedForcedFlags(
            ApplicationEnum.SynchroArtsApp,
        );

        const headers: HeadersInit = token
            ? {
                  authorization: `Bearer ${token}`,
              }
            : {};

        try {
            const url = CONFIG.VITE_MS_FEATURE_FLAG as string;
            const options = {
                method: 'GET',
                headers,
            };

            const response = await fetch(url, options);
            const flagsFromMS = await response.json();

            if (areDevToolsActive()) {
                sendToDevTools('set-state', {
                    name: ApplicationEnum.SynchroArtsApp,
                    featureFlags: {
                        ...defaultFeatureFlags,
                        ...flagsFromMS,
                    },
                    forcedFlags: cachedForcedFlags,
                });

                subscribeFromDevTools(
                    'feature-flags-updated',
                    async ({ payload }: { payload: FlagUpdatePayload }) => {
                        this.featureFlags = {
                            ...this.featureFlags,
                            ...payload.forcedFlags,
                        };

                        this.broadcast(this.featureFlags);
                    },
                );
            }

            this.featureFlags = {
                ...defaultFeatureFlags,
                ...flagsFromMS,
                ...cachedForcedFlags,
            };

            this.broadcast(this.featureFlags);
        } catch (error) {
            log.error(
                `Failed to get feature flag`,
                EventIds.RequestFlagsFailed,
                error,
            );
        }

        return true;
    }
}

export const featureFlagService = new FeatureFlagService();
