/* eslint-disable */
/**
 * !!!DO NOT EDIT THIS FILE!!!
 *
 * The following code is fully generated from the definitions provided in the Analytics AirTable definition.
 * You can regenerate it with "yarn generate:analytics:v2"
 */
import { AbstractSegmentServiceV2, AnalyticsApplication, AnalyticsPlatformName } from '@landr/core';

export enum AccountLanguage {
    de = 'de',
    en = 'en',
    es = 'es',
    fr = 'fr',
    it = 'it',
    ja = 'ja',
    pt = 'pt',
}

export enum CtaGoal {
    AccessCoursePack = 'Access Course Pack',
    AccessMusixmatch = 'Access Musixmatch',
    AddClients = 'Add Clients',
    AddtoCalendarGoogle = 'Add to Calendar - Google',
    AddtoCalendariCal = 'Add to Calendar - iCal',
    AddtoCalendarOutlook = 'Add to Calendar - Outlook',
    AllowFileDownload = 'Allow File Download',
    BacktoPreviousStep = 'Back to Previous Step',
    BrowseFreeSamples = 'Browse Free Samples',
    BrowseNetwork = 'Browse Network',
    BrowsePros = 'Browse Pros',
    BrowseSamples = 'Browse Samples',
    BrowseServiceCategory = 'Browse Service Category',
    BrowseTopService = 'Browse Top Service',
    BuySamples1000Credits = 'Buy Samples 1000 Credits',
    BuyUnlimitedMP3 = 'Buy Unlimited MP3',
    Checkout = 'Checkout',
    ChooseFormat = 'Choose Format',
    CompleteProfileOver50Percent = 'Complete Profile Over 50 Percent',
    CopyLink = 'Copy Link',
    CopyPromolink = 'Copy Promolink',
    CreateProfile = 'Create Profile',
    CreateQuote = 'Create Quote',
    Download3rdPartyPlugin = 'Download 3rd Party Plugin',
    DownloadPluginLandrChromatic = 'Download Plugin - Landr Chromatic',
    DownloadPluginLandrDesktopApp = 'Download Plugin - Landr Desktop App',
    DownloadPluginLandrFXAccoustic = 'Download Plugin - Landr FX Accoustic',
    DownloadPluginLandrFXBass = 'Download Plugin - Landr FX Bass',
    DownloadPluginLandrFXBeats = 'Download Plugin - Landr FX Beats',
    DownloadPluginLandrFXElectric = 'Download Plugin - Landr FX Electric',
    DownloadPluginLandrFXVoice = 'Download Plugin - Landr FX Voice',
    DownloadPluginLandrGuitar = 'Download Plugin - Landr Guitar',
    DownloadPluginLandrRePitchElements = 'Download Plugin - Landr RePitch Elements',
    DownloadPluginLandrSamples = 'Download Plugin - Landr Samples',
    DownloadPluginLandrSessions = 'Download Plugin - Landr Sessions',
    DownloadPluginLethal = 'Download Plugin - Lethal',
    DownloadSample = 'Download Sample',
    DownloadVocAlign = 'Download VocAlign',
    FavouriteSample = 'Favourite Sample',
    FindaPro = 'Find a Pro',
    GetALC = 'Get ALC',
    GetDeal = 'Get Deal',
    GetMixFeedback = 'Get Mix Feedback',
    GetNewFormat = 'Get New Format',
    GetTier1Plan = 'Get Tier 1 Plan',
    GetTier2Plan = 'Get Tier 2 Plan',
    GetTier3Plan = 'Get Tier 3 Plan',
    GotoChromatic = 'Go to Chromatic',
    GotoCommunityChannels = 'Go to Community Channels',
    GotoCourses = 'Go to Courses',
    GotoCreator = 'Go to Creator',
    GotoDistribution = 'Go to Distribution',
    GotoLibrary = 'Go to Library',
    GotoMessages = 'Go to Messages',
    GotoNetwork = 'Go to Network',
    GotoPlugins = 'Go to Plugins',
    GotoProjects = 'Go to Projects',
    GotoReleases = 'Go to Releases',
    GotoSamples = 'Go to Samples',
    GotoSessions = 'Go to Sessions',
    GotoStripeBilling = 'Go to Stripe Billing',
    InstallPluginLandrChromatic = 'Install Plugin - Landr Chromatic',
    InstallPluginLandrDesktopApp = 'Install Plugin - Landr Desktop App',
    InstallPluginLandrFXAccoustic = 'Install Plugin - Landr FX Accoustic',
    InstallPluginLandrFXBass = 'Install Plugin - Landr FX Bass',
    InstallPluginLandrFXBeats = 'Install Plugin - Landr FX Beats',
    InstallPluginLandrFXElectric = 'Install Plugin - Landr FX Electric',
    InstallPluginLandrFXSuite = 'Install Plugin - Landr FX Suite',
    InstallPluginLandrFXVoice = 'Install Plugin - Landr FX Voice',
    InstallPluginLandrGuitar = 'Install Plugin - Landr Guitar',
    InstallPluginLandrRePitchElements = 'Install Plugin - Landr RePitch Elements',
    InstallPluginLandrSamples = 'Install Plugin - Landr Samples',
    InstallPluginLandrSessions = 'Install Plugin - Landr Sessions',
    InstallPluginLethal = 'Install Plugin - Lethal',
    JoinSession = 'Join Session',
    LearnaboutChromatic = 'Learn about Chromatic',
    LearnaboutSessions = 'Learn about Sessions',
    NextStep = 'Next Step',
    OpeninCreator = 'Open in Creator',
    OpenSharingDrawer = 'Open Sharing Drawer',
    RedeemLicence = 'Redeem Licence',
    ReviewOrder = 'Review Order',
    SearchNetwork = 'Search Network',
    ShowMore = 'Show More',
    SkipStep = 'Skip Step',
    StartNewMessage = 'Start New Message',
    StartTrial = 'Start Trial',
    StartVocAlign = 'Start VocAlign',
    ToggleOffAutoTrigger = 'Toggle Off Auto-Trigger',
    ToggleOnAutoTrigger = 'Toggle On Auto-Trigger',
    TryCreator = 'Try Creator',
    TurnOffAutoPlay = 'Turn Off Auto-Play',
    TurnOnAutoPlay = 'Turn On Auto-Play',
    UpgradeALC = 'Upgrade ALC',
    UpgradeDistributionPlan = 'Upgrade Distribution Plan',
    UpgradeMasteringPlan = 'Upgrade Mastering Plan',
    UpgradePlan = 'Upgrade Plan',
    UseABPlayer = 'Use AB Player',
    ViewAAPPricing = 'View AAP Pricing',
    ViewAdvancedMasterSettings = 'View Advanced Master Settings',
    ViewALCPricing = 'View ALC Pricing',
    ViewAutoTunePlugin = 'View Auto-Tune Plugin',
    ViewBeeperPlugin = 'View Beeper Plugin',
    ViewBlueCatFlangerPlugin = 'View Blue Cat Flanger Plugin',
    ViewBoogexPlugin = 'View Boogex Plugin',
    ViewCourse = 'View Course',
    ViewDesktopAppLandingPage = 'View Desktop App Landing Page',
    ViewDetail = 'View Detail',
    ViewDistributionTrends = 'View Distribution Trends',
    ViewDistrokidComparison = 'View Distrokid Comparison',
    ViewLandrFXBundle = 'View Landr FX Bundle',
    ViewLandrFXPlugin = 'View Landr FX Plugin',
    ViewLatencyPlugin = 'View Latency Plugin',
    ViewLethalPlugin = 'View Lethal Plugin',
    ViewMessages = 'View Messages',
    ViewNewDropdown = 'View New Dropdown',
    ViewNotifications = 'View Notifications',
    ViewOldSkoolVerbPlusPlugin = 'View OldSkoolVerb Plus Plugin',
    ViewOvertoneGEQPlugin = 'View Overtone GEQ Plugin',
    ViewPanCakePlugin = 'View PanCake Plugin',
    ViewPlugin = 'View Plugin',
    ViewPricing = 'View Pricing',
    ViewProject = 'View Project',
    ViewPromoCard = 'View Promo Card',
    ViewPromolink = 'View Promolink',
    ViewRecorderPlugin = 'View Recorder Plugin',
    ViewReferralRewards = 'View Referral Rewards',
    ViewSamplesPlugin = 'View Samples Plugin',
    ViewServices = 'View Services',
    ViewSessionsPlugin = 'View Sessions Plugin',
    ViewSessionsShortcut = 'View Sessions Shortcut',
    ViewSpanPlugin = 'View Span Plugin',
    ViewStereoTouchPlugin = 'View Stereo Touch Plugin',
    ViewStudioYearlyCourses = 'View Studio Yearly Courses',
    ViewTripleEQ4Plugin = 'View Triple EQ 4 Plugin',
    WaitzineLinkClick = 'Waitzine Link Click',
    WatchReferenceMasteringVideo = 'Watch Reference Mastering Video',
}

export enum CtaType {
    Button = 'Button',
    Card = 'Card',
    Dropdown = 'Dropdown',
    EllipsisMenu = 'Ellipsis Menu',
    PlainText = 'Plain Text',
    Toggle = 'Toggle',
}

export enum Currency {
    CAD = 'CAD',
}

export enum InteractionSource {
    AddAction = 'Add Action',
    AssetAction = 'Asset Action',
    AssetActionDropdown = 'Asset Action - Dropdown',
    AssetActionFeed = 'Asset Action - Feed',
    AssetActionFile = 'Asset Action - File',
    AssetActionMain = 'Asset Action - Main',
    Automatic = 'Automatic',
    Banner = 'Banner',
    BellNotification = 'Bell Notification',
    BounceFolder = 'Bounce Folder',
    CalloutBanner = 'Callout Banner',
    CancelConfirmationModal = 'Cancel Confirmation Modal',
    CancelFeatureLossModal = 'Cancel Feature Loss Modal',
    CancelOptions = 'Cancel Options',
    CancelReasonModal = 'Cancel Reason Modal',
    CardAction = 'Card Action',
    CategoryTitle = 'Category Title',
    CommentAction = 'Comment Action',
    CompareModal = 'Compare Modal',
    CreatorAction = 'Creator Action',
    Direct = 'Direct',
    DownloadAllAction = 'Download All Action',
    DragandDrop = 'Drag and Drop',
    Email = 'Email',
    HeroBanner = 'Hero Banner',
    InvitationModal = 'Invitation Modal',
    LibraryAction = 'Library Action',
    LibraryEmptyState = 'Library Empty State',
    LibraryTray = 'Library Tray',
    MainAction = 'Main Action',
    MasterFormatModal = 'Master Format Modal',
    MasterPreviewModal = 'Master Preview Modal',
    MasterWaitzine = 'Master Waitzine',
    MasterWaitzineSlide1 = 'Master Waitzine - Slide 1',
    MasterWaitzineSlide2 = 'Master Waitzine - Slide 2',
    MasterWaitzineSlide3 = 'Master Waitzine - Slide 3',
    MasterWaitzineSlide4 = 'Master Waitzine - Slide 4',
    MasterWaitzineSlide5 = 'Master Waitzine - Slide 5',
    MasterWaitzineSlide6 = 'Master Waitzine - Slide 6',
    MessagesAction = 'Messages Action',
    MessagesDropdown = 'Messages Dropdown',
    MobileNav = 'Mobile Nav',
    MultiselectAction = 'Multiselect Action',
    Navbar = 'Navbar',
    NewAction = 'New Action',
    NewActionAlbumMaster = 'New Action - Album Master',
    NewActionMaster = 'New Action - Master',
    NewActionUpload = 'New Action - Upload',
    NewFormatDropdown = 'New Format Dropdown',
    Notification = 'Notification',
    PlanDetailModal = 'Plan Detail Modal',
    PluginCard = 'Plugin Card',
    Popover = 'Popover',
    PricingCard = 'Pricing Card',
    PricingNav = 'Pricing Nav',
    ProfileCardAction = 'Profile Card Action',
    ProfileSurvey = 'Profile Survey',
    ProjectsAction = 'Projects Action',
    ReleaseSplitDetailModal = 'Release Split Detail Modal',
    RetentionOfferModal = 'Retention Offer Modal',
    RoomCard = 'Room Card',
    ServiceBrief = 'Service Brief',
    ServiceQuote = 'Service Quote',
    SessionsNavbarShortcut = 'Sessions Navbar Shortcut',
    SettingAction = 'Setting Action',
    SharedLink = 'Shared Link',
    StartVocAlignModal = 'Start VocAlign Modal',
    TextAction = 'Text Action',
    TrendingCharts = 'Trending Charts',
    UpdateModal = 'Update Modal',
    UpsellButton = 'Upsell Button',
    UpsellCard = 'Upsell Card',
    UpsellModal = 'Upsell Modal',
    UpsellModalFreeUserFreeDownload = 'Upsell Modal - Free User Free Download',
    UpsellModalFreeUserPaidDownload = 'Upsell Modal - Free User Paid Download',
    UpsellModalPaidUserPaidDownload = 'Upsell Modal - Paid User Paid Download',
    UseCreditModal = 'Use Credit Modal',
    WaitzineWhileMastering = 'Waitzine While Mastering',
    WaitzineWhilePBS = 'Waitzine While PBS',
    WaitzineWhilePreviewing = 'Waitzine While Previewing',
    WelcomeModal = 'Welcome Modal',
    WizardReviewStep = 'Wizard Review Step',
}

export enum LicenceType {
    CrossgradeLicense = 'Crossgrade License',
    NewLicense = 'New License',
    ThreeMonthRentalLicense = 'Three-Month Rental License',
    UpgradeLicense = 'Upgrade License',
}

export enum LoginMethod {
    Apple = 'Apple',
    Facebook = 'Facebook',
    Google = 'Google',
    Manual = 'Manual',
}

export enum PaymentGateway {
    AppStore = 'App Store',
    Fusebill = 'Fusebill',
    Legacy = 'Legacy',
    PlayStore = 'Play Store',
    Stripe = 'Stripe',
    StripeConnect = 'Stripe Connect',
}

export enum Platform {
    Desktop = 'Desktop',
    MobileApp = 'Mobile App',
    Plugin = 'Plugin',
    Web = 'Web',
}

export enum SignupMethod {
    Apple = 'Apple',
    Facebook = 'Facebook',
    Google = 'Google',
    Manual = 'Manual',
}

export enum SignupReferrerApplication {
    Chromatic = 'Chromatic',
    Distribution = 'Distribution',
    Education = 'Education',
    Mastering = 'Mastering',
    Network = 'Network',
    NoHomePage = 'No Home Page',
    Other = 'Other',
    Plugins = 'Plugins',
    Projects = 'Projects',
    Samples = 'Samples',
    Sessions = 'Sessions',
    SynchroArts = 'SynchroArts',
}

export enum SignupReferrerContent {
    MasteringPreviewBeforeSignup = 'Mastering Preview Before Signup',
}

export enum TrialInitiator {
    CustomerSupport = 'Customer Support',
    User = 'User',
}

export enum TrialType {
    CreditandTimeBased = 'Credit and Time Based',
    CreditBased = 'Credit Based',
    TimeBased = 'Time Based',
}

export enum UserEmailsCurrentConsentType {
    Explicit = 'Explicit',
    Implied = 'Implied',
}

export enum UserLanguage {
    de = 'de',
    en = 'en',
    es = 'es',
    fr = 'fr',
    it = 'it',
    ja = 'ja',
    pt = 'pt',
}

export enum UserSignupReferrerApplication {
    Chromatic = 'Chromatic',
    Distribution = 'Distribution',
    Education = 'Education',
    Mastering = 'Mastering',
    Network = 'Network',
    NoHomePage = 'No Home Page',
    Other = 'Other',
    Plugins = 'Plugins',
    Projects = 'Projects',
    Samples = 'Samples',
    Sessions = 'Sessions',
    SynchroArts = 'SynchroArts',
}

export type CTAClickedProperties = {
    /**
     * cta goal = the purpose of the CTA (i.e. master track, sample download, album creation/initialization)
     */
    ctaGoal: CtaGoal;
    /**
     * cta type = the type of CTA {e.g.: button, interstitial, banner}
     */
    ctaType: CtaType;
    /**
     * interaction source = interaction the user took to perform the action (e.g.: name of notification, cta prompt, etc.)
     */
    interactionSource: InteractionSource;
    /**
     * Name of the carousel (e.g. Daily New Releases) the Sample pack belongs to. This was created in order to not crowd the interaction_source event property.
     */
    ctaContentGroup?: string;
    /**
     * Name of the tab (e.g. Pop, House, Lofi) the sample / sample pack belongs to, at the given time. This was created in order to not crowd the interaction_source event property.
     */
    ctaContentList?: string;
    /**
     * cta destination = generally a URL destination of clicking the CTA. Before the 2021 schema update, this is called CTA target (which is confusing with CTA goal).
     */
    ctaDestination?: string;
    /**
     * cta text = the literal text displayed of the CTA (if applicable). Pass this text in English, regardless of the language that the user sees.
     */
    ctaText?: string;
    /**
     * platform = Desktop, Web, Plugin, Mobile App
     */
    platform?: Platform;
};

export type LoggedInProperties = {
    /**
     * is impersonate = indicates if the login was initiated by the user or on behalf of the user
     */
    isImpersonate: boolean;
    /**
     * login method = method by which the user is loggin in (i.e. Google, Facebook, Apple, Manual)
     */
    loginMethod: LoginMethod;
    /**
     * utm campaign = the utm campaign from the page url (custom field)
     */
    utmCampaign?: string;
    /**
     * utm content = the utm content from the page url (custom field)
     */
    utmContent?: string;
    /**
     * utm medium = the utm medium from the page url (custom field)
     */
    utmMedium?: string;
    /**
     * utm source = the utm source from the page url (custom field)
     */
    utmSource?: string;
    /**
     * utm term = the utm term from the page url (custom field)
     */
    utmTerm?: string;
};

export type OrderCompletedProperties = {
    /**
     * currency =  CAD. This property must be named this way for GTM/GA/Facebook Ads, and always set to CAD to populate the conversion value in CAD for google/facebook ads
     */
    currency: Currency;
    /**
     * discount = sum of all discounts applied to all products within the order, reflected in native currency. This is a recomended Segment name. Note: for "Order Completed" (FE) and "Checkout Started" (BE), this is sum of all discounts in CAD as the currency is set to "CAD" as always for  "Order Completed" (FE) and "Checkout Started" (BE)
     */
    discount: number;
    /**
     * order id = order/ transaction id
     */
    orderId: string;
    /**
     * payment gateway = platform used to handle payment (e.g.: Stripe, Fusebill)
     */
    paymentGateway: PaymentGateway;
    /**
     * products = array of products purchased. This array contains product details such as product name, price, type, quantity, etc.  In Air Table see tab Event Property Group for a list of all elements of this array.
     */
    products: any[];
    /**
     * Revenue = revenue($) associated with the transaction (excluding tax, including discounts) in CAD. It is equal to base_amount - discount
     */
    revenue: number;
    /**
     * Defines the type of SynchroArts product licence that was purchased e.g. whether it was a 3-month rental or a full licence.
     */
    licenceType?: LicenceType;
};

export type SignedUpProperties = {
    /**
     * account_language = language selected in user account settings
     */
    accountLanguage: AccountLanguage;
    /**
     * landr_email = user's landr email, as an event property. If a user changed their email then this is the new value, while previous_landr_email is the previous value.
     */
    landrEmail: string;
    /**
     * signup method = signup method (i.e. Manual entry, Facebook, Google, Apple)
     */
    signupMethod: SignupMethod;
    /**
     * signup referrer = application associated with the page immediately before user signup. This replaces what was '[L - Homepage]' in the previous schema.
     */
    signupReferrerApplication: SignupReferrerApplication;
    /**
     * first name = user's first name. 1) Fetch on Sign Up : where signup_method= Google / FB or where signup_method= Manual apply what the user inputted as 'name'2) Update on Account Updated according to first name user inputs in their account settings
     */
    firstName?: string;
    /**
     * last name = user's last name. 1) Fetch on Sign Up : where signup_method= Google / FB or where signup_method= Manual 'last_name' will be null2) Update on Account Updated according to last name user inputs in their account settings
     */
    lastName?: string;
    /**
     * signup referrer = page immediately before user signup
     */
    signupReferrer?: string;
    /**
     * signup_referrer_content = important content of the page viewed before sign up. This property is used to further distinguish product signups according to specific elements of the signed out experience. E.g.: Mastering Preview Before Signup. signup_referrer_content= Mastering Preview Before Signup only if the user generated a mastering preview. It does not apply if the user signed up from the navbar on the PBS page.
     */
    signupReferrerContent?: SignupReferrerContent;
    /**
     * utm campaign = the utm campaign from the page url (custom field)
     */
    utmCampaign?: string;
    /**
     * utm content = the utm content from the page url (custom field)
     */
    utmContent?: string;
    /**
     * utm medium = the utm medium from the page url (custom field)
     */
    utmMedium?: string;
    /**
     * utm source = the utm source from the page url (custom field)
     */
    utmSource?: string;
    /**
     * utm term = the utm term from the page url (custom field)
     */
    utmTerm?: string;
};

export type TrialStartedFrontEndProperties = {
    /**
     * trial initiator= who administrated the trial (i.e. Landr Support or User).
     */
    trialInitiator: TrialInitiator;
    /**
     * trial name = name of trial. When applicable this matches the plan_name of the subscription which the user will be assigned to upon trial completion.
     */
    trialPlanName: string;
    /**
     * trial type = how the trial is regulated (i.e. Credit vs Time Based)
     */
    trialType: TrialType;
};

export class AnalyticsV2Service extends AbstractSegmentServiceV2 {
    constructor(platformNameOverride?: AnalyticsPlatformName) {
        super(
            AnalyticsApplication.SynchroArts,
            typeof window === 'undefined' ? undefined : window,
            platformNameOverride,
        );
    }
    /**
     * Triggered when a user clicks a CTA button on a page. See Notion page for all details of when CTA Clicked is triggered on the website: https://www.notion.so/landr/CTA-Clicked-cc35afb358ca4765ae7c55b19d8a2715
     */
    trackCTAClicked(
        properties: CTAClickedProperties,
    ): Promise<void> {
        return this.track(
            'CTA Clicked',
            {
                cta_goal: properties.ctaGoal,
                cta_type: properties.ctaType,
                interaction_source: properties.interactionSource,
                cta_content_group: properties.ctaContentGroup ?? null,
                cta_content_list: properties.ctaContentList ?? null,
                cta_destination: properties.ctaDestination ?? null,
                cta_text: properties.ctaText ?? null,
                platform: properties.platform ?? null,
            },
        );
    }
    /**
     * Triggered when a user logs into their account
     */
    trackLoggedIn(
        properties: LoggedInProperties,
    ): Promise<void> {
        return this.track(
            'Logged In',
            {
                is_impersonate: properties.isImpersonate,
                login_method: properties.loginMethod,
                utm_campaign: properties.utmCampaign ?? null,
                utm_content: properties.utmContent ?? null,
                utm_medium: properties.utmMedium ?? null,
                utm_source: properties.utmSource ?? null,
                utm_term: properties.utmTerm ?? null,
            },
        );
    }
    /**
     * Triggered in the front end, when a user successfully completes a transaction and the payment gateway returns a success call validating that the payment went through. This event fires only for purchases that are done immediately (new purchase and upgrades), Any payment that is scheduled (recurring payment) or downgrades won't trigger this eventThis event contains a product array. This event is named differently than the backend equivalent (i.e. Purchase Completed and Item Purchased) because the front-end version must be named Order Completed in order to comply with downstream tool needs like GTM / GA. This event will not be sent to Amplitude or used anywhere but in tools that only accept frontend events.
     */
    trackOrderCompleted(
        properties: OrderCompletedProperties,
    ): Promise<void> {
        return this.track(
            'Order Completed',
            {
                currency: properties.currency,
                discount: properties.discount,
                order_id: properties.orderId,
                payment_gateway: properties.paymentGateway,
                products: properties.products,
                revenue: properties.revenue,
                licence_type: properties.licenceType ?? null,
            },
        );
    }
    /**
     * Triggered when a user is added to the database.
     */
    trackSignedUp(
        properties: SignedUpProperties,
    ): Promise<void> {
        return this.track(
            'Signed Up',
            {
                account_language: properties.accountLanguage,
                landr_email: properties.landrEmail,
                signup_method: properties.signupMethod,
                signup_referrer_application: properties.signupReferrerApplication,
                first_name: properties.firstName ?? null,
                last_name: properties.lastName ?? null,
                signup_referrer: properties.signupReferrer ?? null,
                signup_referrer_content: properties.signupReferrerContent ?? null,
                utm_campaign: properties.utmCampaign ?? null,
                utm_content: properties.utmContent ?? null,
                utm_medium: properties.utmMedium ?? null,
                utm_source: properties.utmSource ?? null,
                utm_term: properties.utmTerm ?? null,
            },
        );
    }
    /**
     * Triggered in the front end when a user successfully starts a trial by having the ability to access the trial product (regardless of if they have actually accessed it yet or not). Users may sign up for trials themselves, or they may be administered by Landr Support. This event will not be sent to Amplitude or used anywhere but in tools that only accept frontend events like GTM/GA/Google Ads
     */
    trackTrialStartedFrontEnd(
        properties: TrialStartedFrontEndProperties,
    ): Promise<void> {
        return this.track(
            'Trial Started - Front End',
            {
                trial_initiator: properties.trialInitiator,
                trial_plan_name: properties.trialPlanName,
                trial_type: properties.trialType,
            },
        );
    }
}
