import { createModalContext } from 'modals/createModalContext';
import { ModalStep } from './Types';

export type LinkILokModalData = {
    step: ModalStep;
};

export const {
    modalProvider: LinkILokModalProvider,
    modalContext: LinkILokModalContext,
    useModal: useLinkILokModalContext,
} = createModalContext<LinkILokModalData>({
    closeModalOnLocationChange: true,
});
