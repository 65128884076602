import React, { Suspense, ErrorInfo } from 'react';
import { ErrorBoundaryVariant } from 'types/errorBoundaryVariant';
import MinimalError from 'error/components/MinimalError';
import PageError from 'error/pages/PageError';
import { ErrorBoundaryContextType } from 'contexts/ErrorBoundaryContext/ErrorBoundaryContext';
import { log } from 'utils/log';
import { EventIds } from 'enums/eventIds';
import VerboseError from '../VerboseError';

export interface ErrorBoundaryState {
    hasError: boolean;
    errorUi: JSX.Element | null;
}

export function setTemporaryLocalStorage(
    key: string,
    value: string,
    ttl: number,
) {
    const item = {
        value: value,
        expiry: new Date().getTime() + ttl,
    };

    localStorage.setItem(key, JSON.stringify(item));
}

export function getTemporaryLocalStorage(key: string) {
    const itemString = window.localStorage.getItem(key);

    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

const ImportingModuleFailed = /Importing a module script failed/;
const ModuleImportFailed = 'dynamically imported module:';
const CssPreloadError = /Unable to preload CSS/;

// Use ErrorBoundary at the top level of the App only.
// If you wish to add ErrorBoundary to a page or a component, use `withErrorBoundary` instead.
// For more info consult error/README.md
class ErrorBoundary extends React.Component<
    {
        variant: ErrorBoundaryVariant;
        displayName: string;
        setError?: ErrorBoundaryContextType['setError'];
    },
    ErrorBoundaryState
> {
    state = {
        hasError: false,
        errorUi: null,
    };

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.setState({ hasError: true });
        const errorMessage = error?.message ?? '';
        const isChunkError = ImportingModuleFailed.test(errorMessage);
        const isFailedToFetchDynamically =
            errorMessage.indexOf(ModuleImportFailed) >= 0;
        const isCssPreloadError = CssPreloadError.test(errorMessage);

        if (isChunkError && !getTemporaryLocalStorage('chunk_failed')) {
            setTemporaryLocalStorage('chunk_failed', 'true', 10000);

            log.warn(
                'Failed to dynamically import module - reload',
                EventIds.RetryLazyImportFailed,
                error,
                info,
            );

            window.location.reload();
            return;
        } else if (isChunkError || isFailedToFetchDynamically) {
            log.warn(
                'Failed to dynamically import module',
                EventIds.RetryLazyImportFailed,
                error,
                info,
            );
        } else if (
            isCssPreloadError &&
            !getTemporaryLocalStorage('css_failed')
        ) {
            setTemporaryLocalStorage('css_failed', 'true', 10000);

            log.warn(
                'Failed to preload CSS - reload',
                EventIds.RetryCssPreloadFailed,
                error,
                info,
            );

            window.location.reload();
            return;
        } else if (isCssPreloadError) {
            log.warn(
                'Failed to preload CSS',
                EventIds.RetryCssPreloadFailed,
                error,
                info,
            );
        } else {
            log.fatal(
                `${this.props.displayName} - Component Unhandled Error`,
                EventIds.ComponentUnhandledError,
                error,
                info,
            );
        }

        this.props.setError?.({ variant: this.props.variant });

        this.getErrorUi();
    }

    getErrorUi() {
        switch (this.props.variant) {
            case 'global':
                this.setState({
                    errorUi: <PageError isGlobal />,
                });
                break;
            case 'page':
                this.setState({
                    errorUi: <PageError />,
                });
                break;

            case 'minimal':
                this.setState({
                    errorUi: <MinimalError />,
                });
                break;

            case 'verbose':
                this.setState({
                    errorUi: <VerboseError />,
                });
                break;

            default:
                this.setState({
                    errorUi: null,
                });
                break;
        }
    }

    render() {
        return this.state.hasError ? (
            <Suspense fallback={null}>{this.state.errorUi}</Suspense>
        ) : (
            this.props.children || null
        );
    }
}

export default ErrorBoundary;
