import { EnumToObjectType } from 'types/utils/EnumToObjectType';
import objectFromEntries from 'object.fromentries';

/**
 *
 * @param e - The enum to map
 * @param fn - The function to apply
 * @returns - An object with one function per enum value.
 *
 */
export function enumToObject<E extends string, T>(
    e: {
        [key in E]: string;
    },
    fn: (k: string) => T,
): EnumToObjectType<E, T> {
    const keyValueArr = Object.entries(e).map(([k]) => [k, fn(k)]);

    if (!Object.fromEntries) {
        return objectFromEntries(keyValueArr);
    }

    return Object.fromEntries(keyValueArr) as EnumToObjectType<E, T>;
}
