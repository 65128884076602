import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { RouteNames } from 'enums/routes';

export const Metadata: React.FC<PropsWithChildren<unknown>> = ({
    children,
}) => {
    const { pathname } = useLocation();

    const pageName = (RouteNames as any)[pathname] as string | undefined;

    return (
        <>
            <Helmet>
                {/* Basic metadata */}
                <title>
                    {pageName ? `${pageName} | Synchro Arts` : 'Synchro Arts'}
                </title>
            </Helmet>
            {children}
        </>
    );
};
