import { MaestroProvider } from '@landr/maestro';
import React from 'react';

export const AppStyleProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => (
    <MaestroProvider experiments={{ tabsArrow: true }}>
        {children}
    </MaestroProvider>
);

export default AppStyleProvider;
