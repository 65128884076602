import { UTMCookieSettings } from 'constants/UTMCookieSettings';
import {
    AppState,
    AuthAnalyticsProperties,
    AuthService,
    BrowserPlatformProvider,
    CustomizationParams,
    FusionAuthSSORedirectAuthProvider,
} from '@landr/auth';
import { AnalyticsApplication, Environment } from '@landr/core';
import { getTargetUrl } from 'contexts/AuthenticationContext/helpers/getTargetUrl';
import { CONFIG } from 'utils/config';
import { log } from 'utils/log';
import { analytics } from 'utils/analytics';

const PluginSendAuthToken = 'plugin://api/v1/authenticate/';

export class Auth {
    private authService: AuthService | null = null;
    private offlineUrl: string | null = null;
    private accountTwoFactorUrl: string | null = null;

    public async initialize({
        offlineUrl,
    }: {
        environment: Environment;
        offlineUrl: string;
    }) {
        this.offlineUrl = offlineUrl ?? null;
        this.accountTwoFactorUrl = `${CONFIG.VITE_FUSION_AUTH_DOMAIN}/account/two-factor/?client_id=${CONFIG.VITE_FUSION_AUTH_CLIENT_ID}`;

        const environment = CONFIG.VITE_APP_ENV;

        const platformProvider = new BrowserPlatformProvider({
            environment,
            analytics,
            cookieSettings: UTMCookieSettings,
        });

        const authProvider = new FusionAuthSSORedirectAuthProvider({
            domain: CONFIG.VITE_FUSION_AUTH_DOMAIN,
            clientId: CONFIG.VITE_FUSION_AUTH_CLIENT_ID,
            platformProvider,
            log: log.getInstance('FusionAuthSSORedirectAuthProvider'),
        });

        this.authService = new AuthService({
            authProvider,
            platformProvider,
            environment,
            log: log.getInstance('AuthService'),
        });
    }

    public login(isPluginAuth = false) {
        const customizationParams: CustomizationParams | undefined =
            isPluginAuth ? { hideForgetPassword: true } : undefined;

        this.authService?.login({
            appState: {
                targetUrl: getTargetUrl(),
            },
            homePage: AnalyticsApplication.SynchroArts,
            customizationParams,
        });
    }

    public signup() {
        this.authService?.login({
            isSignup: true,
            appState: {
                targetUrl: getTargetUrl(),
            },
            homePage: AnalyticsApplication.SynchroArts,
        });
    }

    public logout() {
        this.authService?.logout();
    }

    public isSignup(): boolean {
        if (!this.authService) {
            throw new Error('Auth service not initialized');
        }

        return !!this.authService?.getIsSignup();
    }

    public async getAccessToken(): Promise<string | null> {
        if (this.authService) {
            return this.authService.getAccessToken();
        }

        return Promise.resolve(null);
    }

    public getAppState(): AppState | null {
        if (!this.authService) {
            throw new Error('Auth service not initialized');
        }

        return this.authService.getAppState();
    }

    public async getAnalyticsProperties(): Promise<AuthAnalyticsProperties | null> {
        if (!this.authService) {
            throw new Error('Auth service not initialized');
        }
        return this.authService.getAnalyticsProperties();
    }

    public async getUser() {
        if (!this.authService) {
            throw new Error('Auth service not initialized');
        }

        return this.authService.getUser().catch(() => {
            // Silently failed (log is done by the lib), and return no user
            if (this.offlineUrl) {
                window.location.assign(this.offlineUrl);
            }
            return null;
        });
    }

    /**
     * Send auth token with plugin:// schema
     */
    public async sendAuthTokenToPlugin() {
        try {
            const token = (await this.getAccessToken()) ?? '';

            window.open(`${PluginSendAuthToken}${token}`);
        } catch (e) {
            if (log !== null) {
                log.error(
                    'Error getting token for plugin authentication',
                    undefined,
                    e,
                );
            }

            // We need to inform the plugin to close the webview, even if we failed to get the token
            window.open(PluginSendAuthToken);
        }
    }

    getAccountTwoFactorUrl(): string | null {
        return this.accountTwoFactorUrl;
    }
}

export const auth = new Auth();
