import React, { useState, useEffect } from 'react';
import { PricingProvider, Languages, Environment } from '@landr/pricing';
import { auth } from 'utils/auth/auth';
import { CONFIG } from 'utils/config';
import { log } from 'utils/log';
import { ApplicationEnum } from '@landr/core.models';

export const PricingProviderWithToken = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [authorizationToken, setAuthorizationToken] = useState<
        string | undefined
    >(undefined);

    useEffect(() => {
        const getToken = async () => {
            const token = await auth.getAccessToken();

            setAuthorizationToken(token ?? undefined);
        };

        getToken();
    }, []);

    return (
        <PricingProvider
            authorizationToken={authorizationToken}
            language={Languages.English}
            log={log}
            applicationName={ApplicationEnum.SynchroArtsApp}
            env={CONFIG.VITE_APP_ENV as Environment}
            locationPathname={'/'} // This is only relevant for the MFE apps like "/library"
        >
            {children}
        </PricingProvider>
    );
};

export default PricingProviderWithToken;
