import { useCatalogSale } from '@landr/pricing';
import React, { useContext } from 'react';

export type CatalogSaleContextType = {
    hasSale: boolean;
};

export const DefaultCatalogSaleContext = {
    hasSale: false,
};

export const CatalogSaleContext = React.createContext<CatalogSaleContextType>(
    DefaultCatalogSaleContext,
);

export const useCatalogSaleContext = (): CatalogSaleContextType =>
    useContext(CatalogSaleContext);

const WhiteListedSaleNames = ['black_friday_2024'];

export const useCatalogSaleProvider = () => {
    const { data: sale } = useCatalogSale({
        includePrismicContent: false,
    });

    return {
        hasSale: !!sale && WhiteListedSaleNames.includes(sale?.name ?? ''),
    };
};

export const CatalogSaleProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const context = useCatalogSaleProvider();

    return (
        <CatalogSaleContext.Provider value={context}>
            {children}
        </CatalogSaleContext.Provider>
    );
};

export default CatalogSaleProvider;
