// FE Events Ids [ 43000 - 43999 ]
// Reserved events ids - https://www.notion.so/landr/16ca40de80e942f3a8f25f9e944dd70a?v=c3689c08477f42418abeced2159777fd

export enum EventIds {
    // Global errors
    UnhandledError = 43000,
    ComponentUnhandledError,
    RequestFlagsFailed,
    StripePortalLinkError,
    HandledAPIError,
    BootstrapError,

    // UserFailureEvents
    GetUserCurrencyFailed = 43010,
    GetUserProfileFailed,
    GetUserLocationFailed,
    GetUserAgreementsFailed,
    AuthFetchProfileError,
    UnsupportedLocale,
    RequestAccountDeletionError,
    DeleteAccountError,
    SecurityAccountTwoFactorUrlError,
    TwoFactorStartMissingMethodOrTwoFactorIdError,

    // Common
    ProblemDetailsTypeError = 43100,
    ContextMethodNotInitialized,
    FailedToGetGeolocation,
    RetryLazyImportFailed,
    RetryLazyImport,
    ClientUpdateContextPerformReload,
    ClientUpdateContextDisplayBanner,
    RetryCssPreloadFailed,

    // Checkout Error
    PrepareAlcCheckoutError = 43200,
    DeprecatedCheckoutParams = 43201,
    CheckoutQueryParamsMissingError = 43202,
    InvalidProductCode = 43203,

    // (Checkout) - First Promoter Error
    FirstPromoterPromoCodeError = 43204,
    PaymentError = 43205,
    FirstPromoterLinkError = 43206,

    // Apollo Global errors
    ApolloAuthenticationsError = 43500,
    ApolloUnauthorizedError,
    ApolloInternalServerError,
    ApolloNetworkError,
    ApolloError,
    ApolloTraceLogs,

    // Analytics errors
    FailedGetAnalyticsPropertiesError = 43550,

    // Query / Mutation errors
    UndefinedQueryError = 43600,
    UpdateUserCommunicationPreferencesError,
    UpdateProfileError,
    QueryProfileError,
    ChangePasswordError,
    RequestEmailChangeError,
    ChangeEmailError,
    GenerateLicenseFailed,
    StartTrialFailed,
    SyncILokLicensesFailed,
    TwoFactorStartError,
    TwoFactorSendError,
}
