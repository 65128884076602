const ParamsToRemove = ['error', 'error_description'];

/**
 * Get the URI encoded return path (location.pathname + location.search) - used
 * to provide a path back to the current page when prompting the user to temporarily
 * leave the app, such as for authentication or purchase flows.
 *
 * Note: This path will is URI encoded, making it suitable for use with `history.push`,
 * but will not work if embedded in another URL.*
 */
export function getTargetUrl() {
    const params = new URLSearchParams(window.location.search);

    ParamsToRemove.forEach((param) => params.delete(param));

    return encodeURI(`${window.location.pathname}?${params.toString()}`);
}
